import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_DISCOUNT_URL = BASE_API_URL+"discounts"

@Component
export default class DiscountsMixin extends Vue {

  getDiscounts(data: any, tag: string) {
    return this.$httpGetQuery(BASE_DISCOUNT_URL, tag,data);
  }

  getDiscount(id: number, tag: string) {
    return this.$httpGet(BASE_DISCOUNT_URL+"/"+id, tag);
  }

  destroyDiscount(id: string|number, tag: string) {
    return this.$httpDelete(BASE_DISCOUNT_URL+"/"+id, tag);
  }

  createDiscount(data: any, tag: string) {
    return this.$httpPost(BASE_DISCOUNT_URL, tag, data);
  }

  updateDiscount(data: any, tag: string) {
    return this.$httpPut(BASE_DISCOUNT_URL+"/"+data.id, tag, data);
  }

}
