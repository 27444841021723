








































































import Search from "@/components/VEntrySearch.vue";
import {  Source } from "@/models";
import {  Component,  Mixins, Watch } from "vue-property-decorator";
import DiscountsMixin from "@/mixins/http/DiscountsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import Preview from "@/components/Order/Preview.vue";
import { Discount } from "@/models";
import EmptyView from "@/components/EmptyView.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getDiscountsTag = "get_discounts";

@Component({
  components: {
    DataTable,
    Preview,
    Search,
    EmptyView,
    VPageContentSticky,
  }
})
export default class DiscountList extends Mixins(DiscountsMixin) {
  @RegisterHttp(getDiscountsTag) getDiscountsRequest: RequestData;

  items: Discount[] = [];
  page = 1;
  private search: string | null = null;

  columns = [
     { name: "rules", label: "Regole" },
    { name: "kind", label: "Tipo" },

  ];

  created() {
    this.subscribe();
  }

  onSelected(item: Discount) {
    this.$router.push({ name: "discounts_show", params: { id: item.id } });
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
     if(value>this.meta?.lastPage+1) {
      return;
    }
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getDiscounts(this.payload, getDiscountsTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: Discount[]}) {
    this.page = 1;
    this.items = items.items;
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {

    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
        term: this.search
      }
    };
    return data;
  }

  async loadItems() {

    const [data, errors] = await easync(
      this.getDiscounts(this.payload, getDiscountsTag)
    );

    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getDiscountsRequest?.loading;
  }

  get meta() {
    return this.getDiscountsRequest?.meta;
  }
}
